<template>
    <div v-if="showModal" :class="['main-category-select-modal modal',showModal?'modal-open':'']" style="display: block">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <h3 class="modal-content__title">Bitte wähle eine Kategorie</h3>
                <p class="modal-content__info">So findest du genau passende Angebote.</p>

                <div class="main-cats categories">
                    <div class="row">
                        <div class="col-12">
                            <button class="no-btn" @click="selectedOption(options[0])">
                                <div class="card bg-jade">
                                    <div class="card__img">
                                        <img src="/assets/icons/beruf.png" alt="Ausbildung &amp; Weiterbildung">
                                    </div>
                                    <div class="card__content">
                                        <h3>Ausbildung &amp; Weiterbildung</h3>
                                        <p>Finde professionelle Ausbildungs- &amp; Weiterbildungsangebote.</p>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div class="col-12">
                            <button class="no-btn" @click="selectedOption(options[1])">
                                <div class="card bg-meganta">
                                    <div class="card__img">
                                        <img src="/assets/icons/hilfe.png" alt="Beratung &amp; Hilfe">
                                    </div>
                                    <div class="card__content">
                                        <h3>Beratung &amp; Hilfe</h3>
                                        <p>Finde Unterstützung in allen Lebenslagen.</p>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div class="col-12">
                            <button class="no-btn" @click="selectedOption(options[2])">
                                <div class="card bg-blue">
                                    <div class="card__img">
                                        <img src="/assets/icons/freizeit.png" alt="Freizeit">
                                    </div>
                                    <div class="card__content">
                                        <h3>Freizeit</h3>
                                        <p>Finde Ideen für deine Freizeitgestaltung.</p>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
</div>
</div>
</template>

<script>
    export default {
        name: "MainCategorySelectModal",
        props: {
            filterId: {
                type: Number,
                default: null,
                required: true,
            },
        },
        data() {
            return {
                showModal: true,
                options: [{
                  "label": "Ausbildung & Weiterbildung",
                  "value": "Ausbildung & Weiterbildung"
              }, 
              {
                  "label": "Beratung & Hilfe",
                  "value": "Beratung & Hilfe"
              },
              {
                  "label": "Freizeit",
                  "value": "Freizeit"
              }]
          }
      },
      mounted(){
        setTimeout(() => {
            this.$refs.button[0].focus();
        }, 2000);
    },
    methods: {
        selectedOption(option){
          this.$emit("filterChanged",this.filterId,[option.label]);
          this.showModal = false;
      },
  }
};
</script>
<style lang="scss" >
    @import '@/scss/_variables.scss';

    .main-category-select-modal{

        .modal-dialog{
         @media (max-width: 420px) {
            padding: 10px;
            max-width: 100%!important;
        }
    }


    .modal-content__title{
     @media (max-width: 420px) {
        font-size: 16px!important;
    }
}

.modal-content__info{
 @media (max-width: 420px) {
    font-size: 13px!important;
}

}

.card__content{

   @media (max-width: 420px) {
    padding: 0 10px;
}

h3{
   @media (max-width: 420px) {
    font-size: 14px!important;
}
}

p{
 @media (max-width: 420px) {
    font-size: 12px!important;
}
}


}

.card__img{
 @media (max-width: 420px) {
    min-width: 100px!important;
}

img{
 @media (max-width: 420px) {
    height: 50px!important;
}
}
}

}

.main-cats{
    button.no-btn{
        margin-bottom: 15px;  
        text-align: left;  
        width: 100%;
    }

    .card {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 125px;
        height: auto;
        background-color: $primary;
        vertical-align: middle;
        box-shadow: 1px 2px 4px 0px #00000040;
        border: none;
        border-radius: 0;
        width: 100%;

        &__img {
            background: $white-color;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            min-width: 125px;
            height: 125px;

            img {
                height: 65px;
                width: auto;
            }

        }

        &__content {
            padding: 0 15px;
            color: $white-color;

            h3 {
                color: $white-color;
                margin-bottom: 10px;
                font-size: 16px;
            }

            p {
                font-size: 13px;

                @media (max-width: 330px) {
                    font-size: 11px !important;
                }
            }
        }
    }
}
</style>